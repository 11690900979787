<template>
  <div class="main-container">
    <app-header />
    <nav-bar />
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>Inbox</v-card-title>
            <v-card-text>
              <v-data-table :headers="headers" :items="notifications" :loading="loadingNotifications"
              loading-text="Loading notifications..." :show-select="selectAvailable" v-model="notificationsSelected"
              item-selectable="selectable" :single-select="false" item-key="file_id">
                <template v-slot:item.is_urgent="{ item }">
                  <boolean-label v-bind:active="item.is_urgent"/>
                </template>
                <template v-slot:item.status_id="{ item }">
                  <data-status-label :status_id="item.status_id"></data-status-label>
                </template>
                <template v-slot:item.doc_date="{ item }">
                  <span>{{ getDate(item.doc_date) }}</span>
                </template>
                <template v-slot:item.urgent="{ item }">
                  <boolean-label v-bind:active="item.urgent"/>
                </template>
                <template v-slot:item.file_id="{ item }">
                  <router-link class="tab-router-link" :to="'/library/edit/'+item.file_id">
                    {{ item.file_id }}
                  </router-link>
                </template>
                <template v-slot:item.title="{ item }">
                  <router-link class="tab-router-link" :to="'/library/edit/'+item.file_id">
                    {{ item.title }}
                  </router-link>
                </template>
                <template v-slot:item.user_email="{ item }">
                  <span v-if="item.user_email.includes('@anonymize.com')"><i>User deleted</i></span>
                  <span v-else>{{item.user_email}}</span>
                </template>
                <template v-slot:item.last_historic_doc_last_user_email="{ item }">
                  <span v-if="item.last_historic_doc_last_user_email
                  && item.last_historic_doc_last_user_email.includes('@anonymize.com')"><i>User deleted</i></span>
                  <span v-else>{{item.last_historic_doc_last_user_email}}</span>
                </template>
                <template v-slot:item.approver_user_email="{ item }">
                  <span v-if="item.approver_user_email && item.approver_user_email.includes('@anonymize.com')">
                    <i>User deleted</i>
                  </span>
                  <span v-else>{{item.approver_user_email}}</span>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions v-if="selectAvailable">
              <v-row>
              <div class="col text-right">
                <v-btn
                  @click="approverUsersDialog = !approverUsersDialog"
                  :disabled="notificationsSelected.length == 0"
                  outlined
                  rounded>Send to other approver</v-btn>
              </div>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="approverUsersDialog" scrollable max-width="900px">
      <v-card>
        <v-card-title>Send for approval</v-card-title>
        <v-card-text>
          <div>
            <v-data-table
              dense
              :headers="notificationsSelectedHeaders"
              :items="notificationsSelected">
              <template v-slot:item.new_approver_user_id="{ item }">
                <v-autocomplete
                  class="mb-2"
                  v-model="item.new_approver_user_id"
                  :items="approverUsers.filter(
                    (aitem) => aitem.id !== item.user_id && aitem.studies.includes(item.study_id))"
                  item-text="email"
                  item-value="id"
                  hide-details
                  label="Select approver"
                  underlined>
                </v-autocomplete>
              </template>
            </v-data-table>
          </div>
          <div>
            <v-text-field v-model="commentary" required label="Commentary*" underlined clearable></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions style="margin-bottom: 10px;" class="text-right">
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="approverUsersDialog=false" color="error">Close</v-btn>
          <v-btn
            :disabled="approverUsersInvalid"
            outlined
            rounded
            @click="passApproveToOther()">
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import AppHeader from '@/components/generic/AppHeader.vue';
import NavBar from '@/components/generic/NavBar.vue';
import DataStatusLabel from '@/components/generic/DataStatusLabel.vue';
import { fileServices } from '@/services/fileServices';
import { accountServices } from '@/services/accountServices';
import { Constants } from '@/store/constants';
import BooleanLabel from '@/components/generic/BooleanLabel.vue';
import { roleManager } from '@/store/roleManager';
import cookieManager from '@/store/cookieManager';

export default {
  name: 'Inbox',
  components: {
    AppHeader,
    NavBar,
    BooleanLabel,
    DataStatusLabel,
  },
  data() {
    return {
      Constants,
      myUserId: cookieManager.get('user_id', Number),
      // Main table
      loadingNotifications: false,
      selectAvailable: false,
      notifications: [],
      notificationsSelected: [],
      headers: [
        { text: 'Doc. ID', value: 'file_id' },
        { text: 'Title', value: 'title' },
        { text: 'Urgent', value: 'urgent' },
        { text: 'Action', value: 'status_id' },
        { text: 'Action on date', value: 'last_historic_doc_date_timestamp' },
        { text: 'Created by', value: 'user_email' },
        { text: 'Last modifier', value: 'last_historic_doc_last_user_email' },
        { text: 'Approver email', value: 'approver_user_email' },
        { text: 'Commentary', value: 'commentary' },
      ],
      // Approver dialog
      approverUsersDialog: false,
      approverUsers: [],
      notificationsSelectedHeaders: [
        { text: 'Doc. ID', value: 'file_id' },
        { text: 'Title', value: 'title' },
        { text: 'New approver', value: 'new_approver_user_id' },
      ],
      commentary: '',
    };
  },
  mounted() {
    this.getInboxData();
    this.getApproverUsers();
  },
  methods: {
    getInboxData() {
      this.loadingNotifications = true;
      fileServices.getInboxData()
        .then((response) => {
          this.loadingNotifications = false;
          this.notifications = response.data.map((item) => {
            const a = { ...item };
            a.isSelectable = a.approver_user_id === this.myUserId || roleManager.isAnyAdmin();
            if (a.isSelectable) this.selectAvailable = true;
            a.last_historic_doc_date_timestamp = this.getDate(a.last_historic_doc_date_timestamp);
            a.new_approver_user_id = null;
            return a;
          });
        })
        .catch(() => { this.loading = false; });
    },
    getApproverUsers() {
      accountServices.getUserApprovers(0)
        .then((response) => {
          this.approverUsers = response.data
            .filter((item) => /* item.id !== this.data_model.user.id && */ this.myUserId !== item.id);
        })
        .catch(() => {});
    },
    getDate(d) {
      return new Date(d)
        .toLocaleString('es-CL', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
    },
    passApproveToOther() {
      // loading true
      const responses = fileServices.passApproveToOtherList(this.notificationsSelected);
      Promise.all(responses)
        .then(() => {
          this.approverUsersDialog = false;
          // this.$router.push('/library');
          this.notifications = [];
          this.notificationsSelected = [];
          this.getInboxData();
        })
        .catch(() => {
          // this.loading = false;
        });
    },
  },
  computed: {
    approverUsersInvalid() {
      const pendingSelected = this.notificationsSelected.filter((item) => item.new_approver_user_id === null);
      return pendingSelected.length > 0 || this.commentary === '' || this.commentary === null;
    },
  },
};
</script>
<style scoped>
  /deep/ table .v-simple-checkbox.v-simple-checkbox--disabled {
    display: none !important;
  }
</style>
